@use "@angular/material" as mat;

@include mat.all-component-typographies();
@include mat.core();
//@import "@angular/material/theming";
//@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "ngx-toastr/toastr";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "drawflow/dist/drawflow.min.css";

$mitp-default-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: mat.m2-define-palette(mat.$m2-blue-palette),
			accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400),
			warn: mat.m2-define-palette(mat.$m2-red-palette),
		),
	)
);
$mitp-dark-theme: mat.m2-define-dark-theme(
	(
		color: (
			primary: mat.m2-define-palette(mat.$m2-blue-palette),
			accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400),
			warn: mat.m2-define-palette(mat.$m2-red-palette),
		),
	)
);

@include mat.all-component-themes($mitp-default-theme);

.darkTheme {
	@include mat.all-component-colors($mitp-dark-theme);
}

//.lightTheme {
//  @include mat.all-component-colors($mitp-default-theme);
//}

nav span {
	color: black;
}

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.vl {
	border-left: 1px solid dodgerblue;
}

.mat-mdc-row:hover {
	background-color: #eeeeee;
}

.blur {
	filter: blur(15px);
}

.mat-icon-span-prefix {
	margin-right: 8px;
}

/* TODO(mdc-migration): The following rule targets internal class of card that may no longer apply for the MDC version.*/
mat-card {
	padding: 16px;
}

mitp-drug::before {
	content: url("assets/images/pill.png");
}

mitp-drug {
	cursor: pointer;
}

.mat-column-tags {
	max-width: 260px !important;
	min-width: 260px !important;
}

a[href ^= 'mitp://videos/']::before
{
	content: url("assets/images/video.png");
}

a[href ^= 'mitp://protocols/']::before
{
	content: url("assets/images/protocol.png");
}

a[href ^= 'mitp://attachments/']::before
{
	content: url("assets/images/attach-file.png");
}

a[href^="tel:"]::before {
	content: url("assets/images/phone-call.png");
}

a[href^="mailto:"]::before {
	content: url("assets/images/mail.png");
}

mitp-drug.shortage::before {
	content: url("assets/images/shortage.png");
}

$globe-width:   12px;
$globe-height:  28px;
$globe-spacing: 40px;
$globe-spread: 3px;
$light-off-opacity: 0.4;

.lightrope {
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	position: absolute;
	z-index: 1;
	margin: -15px 0 0 0;
	padding: 0;
	pointer-events: none;
	width: 100%;
	li {
		position: relative;
		animation-fill-mode: both;
		animation-iteration-count:infinite;
		list-style: none;
		margin: 0;
		padding: 0;
		width: $globe-width;
		height: $globe-height;
		border-radius: 50%;
		margin: $globe-spacing/2;
		display: inline-block;
		background: rgba(0,247,165,1);
		box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,247,165,1);
		animation-name: flash-1;
		animation-duration: 2s;
		&:nth-child(2n+1) {
			background: rgba(0,255,255,1);
			box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,255,255,0.5);
			animation-name: flash-2;
			animation-duration: 0.4s;
		}
		&:nth-child(4n+2) {
			background: rgba(247,0,148,1);
			box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(247,0,148,1);
			animation-name: flash-3;
			animation-duration: 1.1s;
		}
		&:nth-child(odd) {
			animation-duration: 1.8s;
		}
		&:nth-child(3n+1) {
			animation-duration: 1.4s;
		}
		&:before {
			content: "";
			position: absolute;
			background: #222;
			width: ($globe-width - 2);
			height: $globe-height/3;
			border-radius: 3px;
			top: (0 - ($globe-height/6));
			left: 1px;
		}
		&:after {
			content: "";
			top: (0 - $globe-height/2);
			left: $globe-width - 3;
			position: absolute;
			width: $globe-spacing + 12;
			height: ($globe-height/3 * 2);
			border-bottom: solid #222 2px;
			border-radius: 50%;
		}
		&:last-child:after {
			content: none;
		}
		&:first-child {
			margin-left: -$globe-spacing;
		}
	}
}
@keyframes flash-1 {
	0%, 100% { background: rgba(0,247,165,1);
		box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(0,247,165,1);}
	50% { background: rgba(0,247,165,$light-off-opacity);
		box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(0,247,165,0.2);}
}
@keyframes flash-2 {
	0%, 100% { background: rgba(0,255,255,1);
		box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(0,255,255,1);}
	50% { background: rgba(0,255,255,$light-off-opacity);
		box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(0,255,255,0.2);}
}
@keyframes flash-3 {
	0%, 100% { background: rgba(247,0,148,1);
		box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(247,0,148,1);}
	50% { background: rgba(247,0,148,$light-off-opacity);
		box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(247,0,148,0.2);}
}